<template>
  <div class="home">
    <div class="top-middle">
      <div class="top-header">
        <div class="top-header-left">
          <div class="left-container" />
        </div>
        <div class="top-header-right">
          <div class="right-container">
            <h3 style="font-weight: 900;">博鳌亚洲论坛2025年年会</h3>
            <h3 style="font-weight: 100; color: #eeeeee;">BFA Annual Conference 2025</h3>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="middle-container">
          <div class="language">
            <div style="width: 80px; height: 30px; border-radius: 15px; background-color: #2251DB; color: white; display: flex; flex-direction: row; align-items: center; justify-content: center;">
              <span style="cursor: pointer;" @click="onLanguageChanged('zh')"> 中 </span><span v-html="'&nbsp;/&nbsp;'"></span><span style="cursor: pointer;" @click="onLanguageChanged('en')"> EN </span>
            </div>
          </div>

          <div class="login-container">
            <div class="login">
              <div style="text-align: center;">
                <div class="company-name">博鳌亚洲论坛酒店预订系统</div>
                <div class="company-en-name">Boao Forum For Asia Hotel Reservation Service</div>
              </div>
              <div v-if="language === 'zh'" class="login-field">
                <div>{{ $t('lastName') }}<span style="color: red;">（请输入拼音）</span></div>
                <el-input
                  size="large"
                  type="text"
                  v-model="last"
                  @blur="last = last.trim()"
                />
              </div>

              <div v-if="language === 'zh'" class="login-field">
                <div>{{ $t('firstName') }}<span style="color: red;">（请输入拼音）</span></div>
                <el-input
                  size="large"
                  type="text"
                  v-model="first"
                  @blur="first = first.trim()"
                />
              </div>

              <div v-if="language === 'en'" class="login-field">
                <div>{{ $t('firstName') }}</div>
                <el-input
                  size="large"
                  type="text"
                  v-model="first"
                  @blur="first = first.trim()"
                />
              </div>

              <div v-if="language === 'en'" class="login-field">
                <div>{{ $t('lastName') }}</div>
                <el-input
                  size="large"
                  type="text"
                  v-model="last"
                  @blur="last = last.trim()"
                />
              </div>

              <div class="login-field">
                <div>{{ $t('invitationCode') }}</div>
                <el-input
                  size="large"
                  type="text"
                  v-model="invitationCode"
                  @blur="invitationCode = invitationCode.trim()"
                />
              </div>

              <el-button
                class="login-button"
                @click="login"
                :loading="loading"
              >
                {{ $t('login') }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="footer-left">
        <div style="font-size: 20px;">
          <div style="font-weight: 900;">{{ $t('for more information') }}</div>
          <div style="font-size: 14px;">
            <span>{{ $t('footer customer tel') }}: +86-400-098-2996</span>
            <span style="padding-left: 10px;">Email: hotel@bjome.com.cn</span>
          </div>
        </div>
      </div>

      <div class="footer-right">
        <div class="footer-right-container">
          <el-popover
            placement="top"
            width="200"
            trigger="hover">
            <span style="color: #1C47C3;">官网: <a href="http://www.boaoforum.org" target="_blank">www.boaoforum.org</a></span>
            <el-avatar slot="reference" :size="40" :src="officeWeb">
            </el-avatar>
          </el-popover>

          <el-popover
            placement="top"
            width="200"
            trigger="hover">
            <el-image
              style="width: 200px; height: 200px"
              :src="wechatQR"
            ></el-image>
            <el-avatar slot="reference" :size="40" :src="officialAccounts">
            </el-avatar>
          </el-popover>

          <el-popover
            placement="top"
            width="200"
            trigger="hover">
            <el-image
              style="width: 200px; height: 200px"
              :src="appQR"
            ></el-image>
            <el-avatar slot="reference" :size="40" :src="appLogo">
            </el-avatar>
          </el-popover>

          <el-popover
            placement="top"
            width="200"
            trigger="hover">
            <el-image
              style="width: 200px; height: 200px"
              :src="weiboQR"
            ></el-image>
            <el-avatar slot="reference" :size="40" :src="weibo">
            </el-avatar>
          </el-popover>
        </div>
      </div>
    </div>

    <van-popup v-model="showService" closeable :style="{ width: '500px' }">
      <div style="padding: 2.5em 1.4em;">
        <van-row type="flex">
          <van-col span="5" style="background: #eee; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <div>
              <van-icon name="warning-o" size="40" color="orange" />
            </div>
            <div>
              {{ $t('sorry') }}
            </div>
          </van-col>
          <van-col span="19" style="padding: 0 0 0 10px;">
            <div class="word">{{ $t('login notify') }}</div>
            <div class="word">
              <span>{{ $t('customer tel') }}: </span>
              <span class="important-text">+86-400-098-2996</span>
            </div>
            <div class="word">
              <span>{{ $t('customer email') }}: </span>
              <span class="important-text">hotel@bjome.com.cn</span>
            </div>
          </van-col>
        </van-row>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Notify } from 'vant'
import { mapState } from 'vuex'
import { vanLocales } from '@/lang'

export default {
  name: 'Home',
  components: {

  },
  data () {
    return {
      officeWeb: require('@/assets/office_web.png'),
      officialAccounts: require('@/assets/official_accounts.png'),
      appLogo: require('@/assets/app_logo.png'),
      weibo: require('@/assets/weibo.png'),
      wechatQR: require('@/assets/wechat_qr.jpg'),
      weiboQR: require('@/assets/weibo_qr.jpg'),
      appQR: require('@/assets/app_qr.jpg'),
      selectLanguage: this.$store.state.language,
      languages: [
        { code: 'zh', name: '中文' },
        { code: 'en', name: 'English' }
      ],
      last: '',
      first: '',
      invitationCode: '',
      showService: false,
      loading: false
    }
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  methods: {
    onLanguageChanged (value) {
      this.$i18n.locale = value
      vanLocales(value)
      this.$store.dispatch('setLanguage', value)
    },
    check () {
      if (!this.last || !this.last.trim()) {
        return false
      }

      if (!this.first || !this.first.trim()) {
        return false
      }

      if (!this.invitationCode || !this.invitationCode.trim()) {
        return false
      }

      return true
    },
    async login () {
      // check user input
      if (this.check()) {
        // backend login
        try {
          this.loading = true
          const res = await this.$axios.post('/invitation/login', {
            last: this.last.trim(),
            first: this.first.trim(),
            invitationCode: this.invitationCode.trim()
          })

          this.$store.dispatch('setToken', res.token)
          this.$router.push('hotel-list')
        } catch (e) {
          Notify(e.message)
          console.error(e)
        } finally {
          this.loading = false
        }
      } else {
        this.showService = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  font-family: "Microsoft YaHei Bold", "Microsoft YaHei", Arial, Avenir, Helvetica, sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;

  .top-middle {
    flex: 1;
    background-image: url('~@/assets/home_background.jpg');
    background-size: cover;
  }

  .top-header {
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: row;

    .top-header-left {
      .left-container {
        background-image: url('~@/assets/boao2.png');
        background-size: cover;
        width: 220px;
        height: 83px;
      }
    }

    .top-header-left, .top-header-right {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .top-header-right {
      .right-container {
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }

    h3 {
      margin: 0;
      color: white;
    }
  }
}

.middle {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .middle-container {
    background-color: white;
    width: 500px;
    border-radius: 20px;
  }

  .language {
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .login-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .company-name, .company-en-name {
      font-size: 1.9em;
      color: #1C47C3;
      font-weight: bold;
    }

    .company-en-name {
      font-size: 1.2em;
      font-weight: 100 !important;
    }

    .login {
      color: #636363;
      box-sizing: border-box;
      max-width: 500px;
      min-width: 440px;
      width: 60%;
      padding: 0 10px 0 10px;
    }
  }
}

.login-button {
  width: 100%;
  height: 44px;
  border-radius: 10px;
  margin-top: 30px;
  background-color: #2150D8;
  color: white;
  font-size: 1.2em;
  margin-bottom: 40px;
}

.footer {
  height: 80px;
  color: #1E47C2;
  font-size: 15px;
  display: flex;
  flex-direction: row;

  .footer-left, .footer-right {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .footer-right-container {
    display: flex;
    gap: 30px;
  }
}

.link {
  font-family: "Microsoft YaHei Bold", "Microsoft YaHei", Arial, Avenir, Helvetica, sans-serif !important;
  font-size: 15px;
  color: #9e9e9e;
}

.login-field {
  font-weight: 500;
  margin-top: 30px;
  div:first-child {
    box-sizing: border-box;
    padding-bottom: 8px;
    height: 30px;
  }
}
</style>
